.animationContainer
    display: flex
    align-content: center
    align-items: center

.document
    background: var(--colour-light-bg)
    width: 80px
    height: 100px
    display: flex
    flex-direction: row
    flex-wrap: wrap
    align-items: flex-start
    row-gap: 2px
    column-gap: 1px
    padding: 8px
    &>*:nth-child(7n)
        width: 10px
    &>*:nth-child(7n+1)
        width: 8px
    &>*:nth-child(7n+2)
        width: 20px
    &>*:nth-child(7n+3)
        width: 16px
    &>*:nth-child(7n+4)
        width: 8px
    &>*:nth-child(7n+5)
        width: 32px
    &>*:nth-child(7n+6)
        width: 24px
    &>*:nth-child(7n+7)
        width: 12px
    &>*:nth-child(11n)
        width: 10px
    &>*:nth-child(11n+1)
        width: 8px
    &>*:nth-child(11n+2)
        width: 20px
    animation: ingestDocument 20s ease 0s infinite normal none
    position: relative
    z-index: 1
@keyframes ingestDocument
    0%
        left: 0
    25%
        left: 0
    30%, 100%
        left: 250px

.logoQG
    position: relative
    background: white
    border-radius: 80px
    width: 160px
    left: 120px
    z-index: 2
    animation: yumYumQG 20s linear 0s infinite normal none
@keyframes yumYumQG
    0%
        transform: rotate(0deg)
    30%
        transform: rotate(0deg)
    32%
        transform: rotate(360deg)
    34%
        transform: rotate(720deg)
        transform-origin: 50% 50%
    36%, 37%, 38%
        transform: rotate(710deg)
    36.5%, 37.5%, 38.5%
        transform: rotate(730deg)
    39%
        transform: rotate(712deg)
    40%
        transform: rotate(728deg)
    41%
        transform: rotate(720deg)
    43%, 44%, 45%, 46%, 47%
        transform: scale(1)
    43.5%
        transform: scale(1.1)
    44.5%
        transform: scale(1.2)
    45.5%
        transform: scale(1.3)
    46.5%
        transform: scale(1.6)
    100%
        transform: rotate(720deg)

.card
    font-size: var(--font-size-small)
    font-weight: bolder
    display: flex
    gap: 8px
    flex-direction: row
    align-items: center
    justify-content: flex-start

    animation-name: spewExpand, hideUntilOut
    animation-duration: 20s
    animation-iteration-count: infinite
    animation-timing-function: ease
    animation-direction: normal
    animation-fill-mode: none
    color: white

    &>*.title
        align-self: center
        // color: var(--colour-light-text)
        animation: titleColour 20s ease 0s infinite normal none
    &>*.front
        height: 60px
        width: 40px
        display: flex
        justify-content: center
        align-items: center
        backface-visibility: hidden
        animation: hideUntilOut 20s ease 0s infinite normal none
        opacity: 1
        box-shadow: 1px 1px grey
        border-radius: 4px
        &>svg
            font-size: var(--font-size-large)
@keyframes titleColour
    0%, 58%, 66%, 100%
        color: var(--colour-light-text)
    60%, 64%
        color: var(--colour-logo-red)


@keyframes hideUntilOut
    0%, 45%, 95.1%, 100%
        opacity: 0
    46%, 95%
        opacity: 1

@keyframes spewExpand
    0%, 49%
        height: 0
    50%, 100%
        height: 60px
        
.cardsContainer
    position: relative
    display: flex
    flex-direction: column
    gap: 16px
    background: none
    &>*.card>*.front
        animation: hideUntilOut 20s ease 0s infinite normal none
    &>*:nth-child(1)
        &>.title
            animation-delay: 0s
        &>*.front
            background: var(--colour-list-1)
    &>*:nth-child(2)
        &>.title
            animation-delay: 1s
        &>*.front
            background: var(--colour-list-2)
    &>*:nth-child(3)
        &>.title
            animation-delay: 2s
        &>*.front
            background: var(--colour-list-3)
    &>*:nth-child(4)
        &>.title
            animation-delay: 3s
        &>*.front
            background: var(--colour-list-4)
    animation: spewCards 20s ease 0s infinite normal none
@keyframes spewCards
    0%, 48%
        left: 15px
    50%, 100%
        left: 200px
        
.textLine
    background: black
    height: 4px
    width: 4px

.highlight
    animation: highlightOn 20s ease calc( var(--h-n) * 0.2s ) infinite normal none
@keyframes highlightOn
    0%
        background: black
    2.5%
        background: blue
    40%
        background: blue
    42%
        background: black
    100%
        background: black
