@use "./style/screen-height"
@use "./style/tick-list"

:root
    --bg-colour: #292c32
    --theme-colour-orange: #ffa000
    --theme-colour-red: #ec244c
    --font-colour-dirty: #a975d0
    --font-colour-delete: #F5F5F5
 
    --colour-logo-red: #eb2448ff
    --colour-logo-blue: #82afffff
    --colour-logo-yellow: #ffb902
    --colour-logo-orange: #ff6e35


    --colour-correct: green
    --colour-incorrect: red

    --font-size-heading: clamp(2.25rem,5.3vw,5rem)
    --font-size-large: 20pt
    --font-size-caption: 16pt
    --font-size-normal: 12pt
    --font-size-small: 10pt
    --font-size-verysmall: 8pt

    --font-family-sans-serif: Tahoma, Geneva, sans-serif
    --font-family-serif: DejaVu Serif, Garamond, serif
    --font-family-logo: var(--font-family-serif)
    --font-family-body: var(--font-family-sans-serif)
    --font-family-heading: var(--font-family-serif)
    
    --colour-primary-theme: #ec244c
    --colour-primary-text: #ec244c
    --colour-text-on-primary: white
    --colour-primary-textHover: #B12497
    --colour-secondary-theme: #7029a7

    --colour-light-text: #000000
    --colour-dark-text: #ffffff
    --colour-light-bg: #f6faff
    --colour-dark-bg: #292c32

    --width-centering-max: 75%
    --width-centering-blog: 800px

    --colour-list-1: var(--colour-logo-red)
    --colour-list-2: var(--colour-logo-blue)
    --colour-list-3: var(--colour-logo-orange)
    --colour-list-4: var(--colour-logo-yellow)

html
    margin: 0
    padding: 0
    min-height: 100%
body
    margin: 0
    padding: 0
    min-height: 100vh
    font-family: var(--font-family-body)
    font-size: var(--font-size-normal)
    background: var(--colour-light-bg)
    color: var(--colour-light-text)
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale
#root
    min-height: 100vh

h1, h2, h3, h4, h5, h6
    text-align: center
    font-weight: 500

h1, h2
    font-family: var(--font-family-heading)
    color: var(--colour-primary-text)
    line-height: 1.1
h1
    font-size: var(--font-size-heading)
    font-weight: 400

h2
    font-size: var(--font-size-large)
    font-weight: 500

h3
    font-size: var(--font-size-large)
    font-weight: 500

h4
    font-size: var(--font-size-caption)

a
    color: var(--colour-primary-text)
    text-decoration: none
    transition: color 1s
    &:hover
        transition: color 0.2s
        color: var(--colour-primary-textHover)

html
    background: var(--bg-colour)

code
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace

.container
    padding: 16px
    border-radius: 4px

.center
    text-align: center

.centreContent
    max-width: var(--width-centering-max)
    display: flex
    flex-direction: column
    justify-content: space-between
    margin: 0 auto
    padding: 0 10%