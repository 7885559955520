.blog-preview
    // padding: 8px
    h1, h2, h3, h4, h5, h6
        color: black
.blog-container
    max-width: var(--width-centering-blog)
    margin: 0 auto
    padding: 8px
    line-height: 1.5
    h1, h2, h3
        text-align: left
        line-height: 1
    li
        list-style-type: none
.author-container
    display: flex
    gap: 8px
    max-width: 500px
    img
        width: 100px
        height: 100px
