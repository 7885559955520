.title
    h1
        color: black
        font-weight: bold
    h2
        color: black
        font-weight: bold
        &:hover
            color: var(--colour-primary-textHover)
            cursor: pointer
.section
    h1, h2
        color: black