.logo
  height: 3em
  img
    padding: 0
    margin: 0
    height: 100%
    pointer-events: none
  span
    font-size: var(--font-size-large)
    font-weight: 600
    font-family: var(--font-family-logo)
  display: flex
  flex-direction: row
  align-items: center
  gap: 4px
