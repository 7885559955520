.App
  width: 100%
  min-height: 100vh
  display: flex
  flex-direction: column

.App-logo
  height: 2em
  pointer-events: none

@media (prefers-reduced-motion: no-preference)
  .App-logo
    animation: App-logo-spin infinite 20s linear
.App-header
  background-color: var(--colour-light-bg)
  padding: 4px
  display: flex
  flex-direction: column
  font-size: 16pt
  height: max-content
  color: var(--colour-primary-text)
  nav
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    height: max-content
.App-content
    flex-grow: 1
.App-footer
    left: 0
    right: 0
    background: var(--colour-light-bg)
    color: var(--colour-light-text)
    border-top: 2px solid var(--theme-colour-red)
    padding: 8px 24px
    &>div
        display: flex
        flex-direction: row
        justify-content: space-between
        &>div
            display: flex
            flex-direction: column
            gap: 4px
    h4
        // color: var(--font-colour-layer0-normal)
        margin: 16px 0
        text-align: left

@media only screen and (max-width: 768px)
    .App-footer
        &>div
            flex-direction: column
        h4
            margin: 16px 0 4px 0  

.pull-left
  width: max-content
  float: left

.App-link
  color: #61dafb

input[type=text]
  width: 100%
  padding: 12px 20px
  margin: 8px 0
  box-sizing: border-box
  border: 2px solid var(--theme-colour-red)
  border-radius: 4px

textarea
  height: 200px
  width: 100%
  padding: 12px 20px
  margin: 8px 0
  box-sizing: border-box
  border: 2px solid var(--theme-colour-red)
  border-radius: 4px

input[type=submit]
  float: right
  padding: 6px 10px
  margin: 8px 0
  box-sizing: border-box
  border: 2px solid var(--theme-colour-red)
  background-color: var(--theme-colour-red)
  color: white