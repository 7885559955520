.full-height-screen
    min-height: 100vh
    display: flex
    flex-direction: row
    &>.full-width-container
        display: flex
        align-items: center
        justify-content: space-around
        padding: 50px
        gap: 8px
        max-width: var(--width-centering-max)
        height: 100%
        margin: auto
        flex-grow: 1
        &>*
            flex: 1 0
    img, video
        width: 100%
    h1, h2, h3, h4, h5, h6
        text-align: left
.full-height-screen-column
    min-height: 100vh
    display: flex
    flex-direction: column
    padding: 0% 10%
    &>.full-width-container
        display: flex
        align-items: center
        justify-content: space-around
        padding: 8px
        gap: 8px
        max-width: var(--width-centering-max)
        height: 100%
        margin: auto
        flex-grow: 1
        &>*
            flex: 1 0
    img, video
        width: 100%
    h1, h2, h3, h4, h5, h6
        text-align: left
.call-to-link-box
    h2
        text-align: left
        color: var(--colour-dark-text)
    a
        background: var(--colour-primary-theme)
        color: var(--colour-dark-text)
        font-size: var(--font-size-caption)
        padding: 16px
        border-radius: 8px
        line-height: 3
.w1-2
    &>*:nth-child(1)
        flex: 1 0
    &>*:nth-child(2)
        flex: 2 0
.w2-1
    flex-direction: row-reverse
    &>*:nth-child(1)
        flex: 1 0
    &>*:nth-child(2)
        flex: 2 0

@mixin keyframes($name)
    @keyframes #{$name}
        @content
    
.underlay-animation
    @include keyframes(bounce)
        0%, 100% 
            transform: translate(0, 0)
        50% 
            transform: translate(0, -50px)
    &>*
        animation: bounce 1s ease 2 alternate
        @for $i from 1 through 3
            $delay: ($i * 0.1)
            &:nth-child(3n + #{$i})
                animation-delay: #{$delay}s

.with-underlay
    &>div
        display: flex
        gap: 16px
        &>*
            padding: 8px 16px
            border-radius: 16px
        &>*:not(.underlay-container>div)
                &:nth-child(3n+1)
                    background-color: #8eb4fe
                &:nth-child(3n+2)
                    background: #fbc02b
                &:nth-child(3n+3)
                    background: #f04b4b
        
.with-circle-underlay
    background: var(--colour-light-bg)
    &>div
        display: flex
        flex-wrap: wrap
        flex-direction: row
        justify-content: space-around
        gap: 16px
        &>*
            padding: 5% 5%
            border-radius: 50%
            background: #f04b4b
            flex: 1 0
            min-width: 250px
            max-width: 25%
            h1, h2, h3
                color: white
                text-align: center
                font-weight: bold 
            h4
                color: white
                text-align: center
.underlay-container
    color: var(--colour-light-text)
.icon-container
    display: flex
    flex-direction: row
    justify-content: center
    gap: 8px
    svg
        font-size: 2rem
@media only screen and (max-width: 768px)
    .full-height-screen>.full-width-container
        flex-direction: column
        &.with-underlay > div
            flex-direction: column
@media only screen and (min-width: 768px)
    .full-height-screen>.full-width-container
        flex-direction: row
        &.with-underlay
            flex-direction: column
            &>div
                flex-direction: row

.blob-headed-message
    h2
        font-family: var(--font-family-sans-serif)
        background: var(--colour-primary-text)
        color: var(--colour-dark-text)
        width: fit-content
        padding: 4px 32px
        border-radius: 8px
    h3
        font-size: var(--font-size-large)
        font-weight: 600
.massive-message
    h2
        color: white
        font-size: var(--font-size-heading)
    h3
        color: var(--theme-colour-orange)


.colour-tile-list
    color: var(--colour-light-text)
    &>div.full-height-screen:nth-child(odd)>.full-width-container
        @media only screen and (min-width: 768px)
            flex-direction: row-reverse
            &.with-underlay>div
                flex-direction: row-reverse
    &>div:nth-child(3n+2)
        background: #fed4d3
        &>.blob-headed-message>h2
            background: #f04b4b
    &>div:nth-child(3n+3)
        background: #e3ecff
        &>.blob-headed-message>h2
            background: #8eb4fe
    &>div:nth-child(3n+1)
        background: #ffefd0
        &>.blob-headed-message>h2
            background: #fbc02b
