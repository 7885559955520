.styled-menu-btn
    display: flex
    flex-direction: column
    justify-content: space-around
    width: 2rem
    height: 2rem
    background: transparent
    border: none
    cursor: pointer
    padding: 0
    z-index: 10
    &>div
        width: 2rem
        height: 0.25rem
        background: var(--colour-light-text)
        border-radius: 10px
        transition: all 0.3s linear
        position: relative
        transform-origin: 1px