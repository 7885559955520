@mixin keyframes($name)
    @keyframes #{$name}
        @content
    

@include keyframes(slide)
        0%
            transform: translateX(+100%)
        100%
            transform: translateX(0%)   
.styled-nav
    display: flex
    flex-direction: column
    justify-content: center
    background: var(--colour-light-bg)
    height: 100vh
    text-align: left
    padding: 2rem
    position: fixed
    top: 0
    right: 0
    animation: slide 1s ease 1 alternate
    a
        font-size: 2rem
        text-transform: uppercase
        padding: 2rem 0
        font-weight: bold
        letter-spacing: 0.5rem
        color: var(--colour-light-text)
        text-decoration: none
        &:hover
            color: var(--colour-primary-textHover)
.styled-nav-hidden
    display: none

.cross
    position: absolute
    top: 0
    right: 0
    padding: 8px 0px
    width: 3rem
    height: 3rem
    cursor: pointer 
    img
        padding: 0
        margin: 0
        height: 100%